// newOrderSaga.js
import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import {
    fetchNewOrdersSuccess,
    viewNewOrderSuccess,
    fetchOrdersSuccess,
    viewOrderSuccess,
    deleteNewOrderSuccess,
    viewNewOrderFailure,
    deleteNewOrderFailure,
    createNewOrderSuccess,
    fetchNewOrdersFailure,
    createNewOrderFailure,
    searchNewOrdersSuccess,
    searchNewOrdersFailure,
    searchOrdersSuccess,
    searchOrdersFailure,
    patientSuccess,
    patientFailure,
    hospiceSuccess,
    hospiceFailure,
    pharmacySuccess,
    pharmacyFailure,
    nurseSuccess,
    nurseFailure,
    orderTrackingFailure,
    orderTrackingSuccess,
    comfortKitSuccess,
    comfortKitFailure,
    changeStatusSuccess,
    changeStatusFailure,
    driverSuccess,
    driverFailure,
} from '../reducers/newOrders';


const baseUrl = 'https://project.devxtop.com';

function* fetchNewOrdersSaga(action) {
    let { id, rowsPerPage, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.newOrders.currentPage)
    console.log(rowsPerPage)
    const apiCall = () => {
        if (newPage) {
            return axios.post(baseUrl + `/api/orders/list/${id}?page=${newPage}`,
                {
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
        }

        return (
            axios.post(baseUrl + `/api/orders/list/${id}`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(fetchNewOrdersSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(fetchNewOrdersFailure(error.message));
    }
}
function* orderTrackingSaga(action) {
    let { id, rowsPerPage, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.newOrders.currentTrackingPage)
    console.log(rowsPerPage)
    const apiCall = () => {
        if (newPage) {
            return axios.post(baseUrl + `/api/orders/patient/list/${id}?page=${newPage}`,
            {
                records: rowsPerPage
            },
            {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
        }

        return (
            axios.post(baseUrl + `/api/orders/patient/list/${id}`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(orderTrackingSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(orderTrackingFailure(error.message));
    }
}

function* fetchOrdersSaga(action) {
    let { rowsPerPage, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.newOrders.currentPage)
    console.log(rowsPerPage)
    const apiCall = () => {
        if (newPage) {
            return axios.post(baseUrl + `/api/hospice/orders/list?page=${newPage}`,
                {
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
        }

        return (
            axios.post(baseUrl + `/api/hospice/orders/list`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(fetchOrdersSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(fetchOrdersFailure(error.message));
    }
}

function* createNewOrderSaga(action) {
    const { id, hospice_id, nurse_id, patient_id, tax_price, shipping_price, orderProducts } = action.payload;
    let { authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    let params = {
        'hospice_id': hospice_id,
        'nurse_id': nurse_id,
        'patient_id': patient_id,
        'orderProducts': orderProducts
    };
    console.log(params)
    // if (id) {
    //     params = {
    //         id: id,
    //         'hospice_id': 1,
    //         'pharmacy_id': 1,
    //         'nurse_id': 1,
    //         'patient_id': 1,
    //         'tax_price': tax_price ? tax_price : 0,
    //         'shipping_price': shipping_price ? shipping_price : 0,
    //         'orderProducts': orderProducts
    //     }
    // }
    console.log(authToken)
    const apiCall = () => {
        return axios.post(
            baseUrl + '/api/orders/create',
            params,
            {
                headers: {
                    'accept': '*/*',
                    'Authorization': authToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(createNewOrderSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        console.log(error);
        yield put(createNewOrderFailure(error.message));
    }
}

function* deleteNewOrderSaga(action) {
    let { id, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    console.log(authToken);
    const apiCall = () => {
        return (
            axios.delete(baseUrl + `/api/orders/delete/${id}`,
                {

                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                    }
                })

        );
    }
    try {
        const response = yield call(apiCall);
        console.log(response.data);
        yield put(deleteNewOrderSuccess(response.data));
    } catch (error) {
        yield put(deleteNewOrderFailure(error.message));
    }
}

function* viewNewOrderSaga(action) {
    let { id, tab_id, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    console.log(authToken)
    const apiCall = () => {
        return (
            axios.get(baseUrl + `/api/orders/view/${id}`,
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                    }
                })
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(viewNewOrderSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(viewNewOrderFailure(error.message));
    }
}

// Saga function to search NewOrders from the API
function* searchNewOrdersSaga(action) {
    let { searchText, rowsPerPage, authToken, tab_id } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.newOrders.currentPage);
    function apiCall() {
        if (searchText == '') {
            return axios.post(baseUrl + `/api/orders/list/${tab_id}/?page=${newPage}`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        }
        return (
            axios.post(baseUrl + `/api/orders/search/${tab_id}`,
                {
                    search: searchText,
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }

    try {
        const response = yield call(apiCall);
        console.log(response.data)
        yield put(searchNewOrdersSuccess(response.data));
    } catch (error) {
        yield put(searchNewOrdersFailure(error.message));
    }
}

function* searchOrdersSaga(action) {
    let { searchText, rowsPerPage, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.newOrders.currentPage);
    function apiCall() {
        if (searchText == '') {
            return axios.post(baseUrl + `/api/hospice/orders/list?page=${newPage}`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        }
        return (
            axios.post(baseUrl + `/api/hospice/orders/search`,
                {
                    search: searchText,
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }

    try {
        const response = yield call(apiCall);
        console.log(response.data)
        yield put(searchOrdersSuccess(response.data));
    } catch (error) {
        yield put(searchOrdersFailure(error.message));
    }
}

function* newPageSaga(action) {
    const { newPage, rowsPerPage } = action.payload;
    const authToken = sessionStorage.getItem('authToken');
    try {
        const apiCall = () => {
            return axios.post(baseUrl + `/api/orders/list?page=${newPage}`,
                {
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
        }
        const response = yield call(apiCall);
        yield put(fetchNewOrdersSuccess(response.data));
    } catch (error) {
        yield put(fetchNewOrdersFailure(error.message));
    }
}
function* newOrderTrackingPageSaga(action) {
    const { newPage, id, rowsPerPage } = action.payload;
    const authToken = sessionStorage.getItem('authToken');
    try {
        const apiCall = () => {
            return axios.post(baseUrl + `/api/orders/patient/list/${id}?page=${newPage}`,
                {
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
        }
        const response = yield call(apiCall);
        yield put(fetchNewOrdersSuccess(response.data));
    } catch (error) {
        yield put(fetchNewOrdersFailure(error.message));
    }
}

function* hospiceSaga(action) {
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const apiCall = () => {
        return axios.get(baseUrl + '/api/auth/user-by-role/3', {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    try {
        const response = yield call(apiCall);
        yield put(hospiceSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(hospiceFailure(error.message));
    }
}
function* patientSaga(action) {
    const id = action.payload
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const apiCall = () => {
        return axios.get(baseUrl + `/api/auth/hospice-patients/${id}`, {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    try {
        const response = yield call(apiCall);
        yield put(patientSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(patientFailure(error.message));
    }
}
function* nurseSaga(action) {
    const id = action.payload
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const apiCall = () => {
        return axios.get(baseUrl + `/api/auth/hospice-nurses/${id}`, {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    try {
        const response = yield call(apiCall);
        yield put(nurseSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(nurseFailure(error.message));
    }
}
function* pharmacySaga(action) {
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const apiCall = () => {
        return axios.get(baseUrl + '/api/auth/user-by-role/2', {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    try {
        const response = yield call(apiCall);
        yield put(pharmacySuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(pharmacyFailure(error.message));
    }
}

function* comfortKitSaga(action) {
    const id = action.payload;
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const apiCall = () => {
        return axios.get(baseUrl + `/api/comfort-kits/list/${id}` , {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    try {
        const response = yield call(apiCall);
        yield put(comfortKitSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(comfortKitFailure(error.message));
    }
}

function* changeStatusSaga(action) {
    const { tab_id, status, order_ids, driver_id } = action.payload;

    let body = {
        order_ids: order_ids,
    }
    if(status){
        body = {
            ...body,
            status: status,
        }
    }
    if(driver_id){
        body = {
            ...body,
            driver_id: driver_id
        }
    }
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    console.log({status: 1, tab_id: 4, order_ids: order_ids})
    const apiCall = () => {
        return axios.post(baseUrl + `/api/orders/change-status/${tab_id}`,
        body
        , {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    console.log(body)

    try {
        const response = yield call(apiCall);
        yield put(changeStatusSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(changeStatusFailure(error.message));
    }

}

function* driverSaga(action) {
    let authToken;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const apiCall = () => {
        return axios.get(baseUrl + '/api/auth/user-by-role/7', {
            headers: {
                'accept': '*/*',
                'Authorization': authToken,
            }
        });
    }
    try {
        const response = yield call(apiCall);
        yield put(driverSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(driverFailure(error.message));
    }
}



// Saga watcher function to listen for fetch and search NewOrder actions
function* newOrderSaga() {
    yield takeLatest('newOrders/fetchNewOrdersRequest', fetchNewOrdersSaga);
    yield takeLatest('newOrders/orderTrackingRequest', orderTrackingSaga);
    yield takeLatest('newOrders/fetchOrdersRequest', fetchOrdersSaga);
    yield takeLatest('newOrders/createNewOrderRequest', createNewOrderSaga);
    yield takeLatest('newOrders/deleteNewOrderRequest', deleteNewOrderSaga);
    yield takeLatest('newOrders/viewNewOrderRequest', viewNewOrderSaga);
    yield takeLatest('newOrders/newPageRequest', newPageSaga);
    yield takeLatest('newOrders/newOrderTrackingPageRequest', newOrderTrackingPageSaga);
    yield takeLatest('newOrders/searchNewOrdersRequest', searchNewOrdersSaga);
    yield takeLatest('newOrders/searchOrdersRequest', searchOrdersSaga);
    yield takeLatest('newOrders/hospiceRequest', hospiceSaga);
    yield takeLatest('newOrders/pharmacyRequest', pharmacySaga);
    yield takeLatest('newOrders/comfortKitRequest', comfortKitSaga);
    yield takeLatest('newOrders/driverRequest', driverSaga);
    yield takeLatest('newOrders/patientRequest', patientSaga);
    yield takeLatest('newOrders/nurseRequest', nurseSaga);
    yield takeLatest('newOrders/changeStatusRequest', changeStatusSaga);
}

export default newOrderSaga;
