import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MainLayout from '../layout/mainLayout';

// render - dashboard
const DashboardDefault = Loadable(lazy(() => import('../pages/dashboard/index.js')));
const NewOrders = Loadable(lazy(() => import('../pages/recentOrders/NewOrders.js')));
const YourOrders = Loadable(lazy(() => import('../pages/recentOrders/YourOrders.js')));
const OrderTracking = Loadable(lazy(() => import('../pages/recentOrders/OrderTracking.js')));
const Orders = Loadable(lazy(() => import('../pages/recentOrders/Orders.js')));
const Drivers = Loadable(lazy(() => import('../pages/drivers/Drivers.js')));
const FilledOrders = Loadable(lazy(() => import('../pages/recentOrders/FilledOrders.js')));
const DispatchedOrders = Loadable(lazy(() => import('../pages/recentOrders/DispatchedOrders.js')));
const DeliveredOrders = Loadable(lazy(() => import('../pages/recentOrders/DeliveredOrders.js')));
const CreateOrder = Loadable(lazy(() => import('../pages/recentOrders/createOrder.js')));
const ViewOrder = Loadable(lazy(() => import('../pages/recentOrders/viewOrder.js')));

const Medicines = Loadable(lazy(() => import('../pages/medicines/index.js')));
const CreateMedicine = Loadable(lazy(() => import('../pages/medicines/CreateMedicine.js')));
const EditMedicine = Loadable(lazy(() => import('../pages/medicines/EditMedicine.js')));
const ViewMedicine = Loadable(lazy(() => import('../pages/medicines/ViewMedicine.js')));

const ComfortKits = Loadable(lazy(() => import('../pages/comfortKits/index.js')));
const CreateComfortKit = Loadable(lazy(() => import('../pages/comfortKits/CreateComfortKit.js')));
const EditComfortKit = Loadable(lazy(() => import('../pages/comfortKits/EditComfortKit.js')));
const ViewComfortKit = Loadable(lazy(() => import('../pages/comfortKits/ViewComfortKit.js')));

const Users = Loadable(lazy(() => import('../pages/users/index.js')));
const PrivateRoute = Loadable(lazy(() => import('../components/PrivateRoute')));
const CreateUser = Loadable(lazy(() => import('../pages/users/CreateUser.js')));
const ViewUser = Loadable(lazy(() => import('../pages/users/ViewUser.js')));

// ==============================|| MAIN ROUTING ||============================== //

const MainRoutes = {
  path: '/dashboard',
  element: <PrivateRoute component={<MainLayout />}/>,
  children: [
    {
      index: true,
      path: 'new-orders',
      element: <NewOrders />
    },
    {
      path: 'your-orders',
      element: <YourOrders />
    },
    {
      path: 'order-tracking',
      element: <OrderTracking />
    },
    {
      path: 'orders',
      element: <Orders />
    },
    {
      path: 'drivers',
      element: <Drivers />
    },
    {
      path: 'filled-orders',
      element: <FilledOrders />
    },
    {
      path: 'dispatched-orders',
      element: <DispatchedOrders />
    },
    {
      path: 'delivered-orders',
      element: <DeliveredOrders />
    },
    {
      path: 'new-orders/create',
      element: <CreateOrder />
    },
    {
      path: 'your-orders/create',
      element: <CreateOrder />
    },
    {
      path: ':route/view/:id',
      element: <ViewOrder />
    },
    
    // {
    //   path: 'comfortKits',
    //   element: <ComfortKits />
    // },
    // {
    //   path: 'comfortKits/create',
    //   element: <CreateComfortKit />
    // },
    // {
    //   path: 'comfortKits/edit/:id',
    //   element: <EditComfortKit />
    // },
    // {
    //   path: 'comfortKits/view/:id',
    //   element: <ViewComfortKit />
    // },
    // {
    //   path: 'medicines',
    //   element: <Medicines />
    // },
    // {
    //   path: 'medicines/create',
    //   element: <CreateMedicine />
    // },
    // {
    //   path: 'medicines/edit/:id',
    //   element: <EditMedicine />
    // },
    // {
    //   path: 'medicines/view/:id',
    //   element: <ViewMedicine />
    // },
    // {
    //   path: 'users',
    //   element: <Users />
    // },
    // {
    //   path: 'users/create',
    //   element: <CreateUser />
    // },
    // {
    //   path: 'users/view/:id',
    //   element: <ViewUser />
    // },
    
  ]
};

export default MainRoutes;
