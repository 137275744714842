// newOrderSaga.js
import { put, takeLatest, all, call, select } from 'redux-saga/effects';
import axios from 'axios';
import {
    fetchDriversSuccess,
    fetchDriversFailure,
    viewNewOrderSuccess,
    viewNewOrderFailure,
    searchDriversSuccess,
    searchDriversFailure,
    deliverOrderSuccess,
    deliverOrderFailure,
} from '../reducers/drivers';


const baseUrl = 'https://project.devxtop.com';

function* fetchDriversSaga(action) {
    let { id, rowsPerPage, authToken } = action.payload;
    console.log('hello from driver saga')
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.drivers.currentPage)
    console.log(rowsPerPage)
    const apiCall = () => {
        if (newPage) {
            return axios.post(baseUrl + `/api/driver/list?page=${newPage}`,
                {
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                });
        }

        return (
            axios.post(baseUrl + `/api/driver/list`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(fetchDriversSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        yield put(fetchDriversFailure(error.message));
    }
}

function* deliverSaga(action) {
    const { id, email, first_name, last_name, signature, phone_number} = action.payload;
    const authToken = sessionStorage.getItem('authToken');
    const body = {
        order_id: id,
        email: email,
        phone_number: phone_number,
        first_name: first_name,
        last_name: last_name, 
        signature: signature,
    }
    const apiCall = () => {
        return axios.post(
            baseUrl + '/api/driver/deliver',
            body,
            {
                headers: {
                    'accept': '*/*',
                    'Authorization': authToken,
                    'Content-Type': 'application/x-www-form-urlencoded'
                }
            }
        );
    }
    try {
        const response = yield call(apiCall);
        yield put(deliverOrderSuccess(response.data));
        console.log(response.data);
    } catch (error) {
        console.log(error);
        yield put(deliverOrderFailure(error.message));
    }
}

function* deleteDriverSaga(action) {
    let { id, authToken } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    console.log(authToken);
    const apiCall = () => {
        return (
            axios.delete(baseUrl + `/api/orders/delete/${id}`,
                {

                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                    }
                })

        );
    }
    try {
        const response = yield call(apiCall);
        console.log(response.data);
        yield put(deleteNewOrderSuccess(response.data));
    } catch (error) {
        yield put(deleteNewOrderFailure(error.message));
    }
}

// Saga function to search drivers from the API
function* searchDriversSaga(action) {
    let { searchText, rowsPerPage, authToken, tab_id } = action.payload;
    if (!authToken) {
        authToken = sessionStorage.getItem('authToken');
    }
    const newPage = yield select((state) => state.newOrders.currentPage);
    function apiCall() {
        if (searchText == '') {
            return axios.post(baseUrl + `/api/driver/list/?page=${newPage}`,
                {
                    records: rowsPerPage
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        }
        return (
            axios.post(baseUrl + `/api/drivers/search`,
                {
                    search: searchText,
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                })
        );
    }

    try {
        const response = yield call(apiCall);
        console.log(response.data)
        yield put(searchDriversSuccess(response.data));
    } catch (error) {
        yield put(searchDriversFailure(error.message));
    }
}

function* newPageSaga(action) {
    const { newPage, rowsPerPage } = action.payload;
    const authToken = sessionStorage.getItem('authToken');
    try {
        const apiCall = () => {
            return axios.post(baseUrl + `/api/orders/list?page=${newPage}`,
                {
                    records: rowsPerPage,
                },
                {
                    headers: {
                        'accept': '*/*',
                        'Authorization': authToken,
                        'Content-Type': 'application/x-www-form-urlencoded'
                    }
                }
            );
        }
        const response = yield call(apiCall);
        yield put(fetchDriversSuccess(response.data));
    } catch (error) {
        yield put(fetchDriversFailure(error.message));
    }
}

// Saga watcher function to listen for fetch and search NewOrder actions
function* driverSaga() {
    yield takeLatest('drivers/fetchDriversRequest', fetchDriversSaga);
    yield takeLatest('drivers/searchDriversRequest', searchDriversSaga);
    yield takeLatest('drivers/deliverOrderRequest', deliverSaga);
    yield takeLatest('newOrders/newPageRequest', newPageSaga);
}

export default driverSaga;
