// reducers.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  user: null,
  login: false,
  errorLogin: false,
  register: false,
  loading: false,
  authToken: null,
  role_id: null,
};

const authSlice = createSlice({
  name: 'auth',
  initialState,
  reducers: {
    loginRequest: (state) => {
      state.loading = true;
      state.login = false;
      state.errorLogin = false
    },
    loginSuccess: (state, action) => {
      state.loading = false;
      state.user = action.payload.data.user;
      state.authToken = action.payload.data.token;
      state.role_id = action.payload.data.user.role_id;
      sessionStorage.setItem('authToken', `${state.authToken}`);
      sessionStorage.setItem('role_id', `${state.role_id}`);
      state.login = true;
    },
    loginFailure: (state, action) => {
      state.loading = false;
      state.errorLogin = true;
      state.error = action.payload;
    },
    registerRequest: (state) => {
      state.loading = true;
    },
    registerSuccess: (state, action) => {
      state.loading = false;
      state.register = true
    },
    registerFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    }
  },
});

export const {
  loginRequest,
  loginFailure,
  loginSuccess,
  registerRequest,
  registerSuccess,
  registerFailure,
} = authSlice.actions;

export default authSlice.reducer;
