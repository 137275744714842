let dashboard;

const getMenuItems = (role_id)=> {
  if(role_id==2)
  {
    dashboard = {
      id: 'group-dashboard',
      title: 'Orders',
      type: 'group',
      children: [
        {
          id: 'New Orders',
          title: 'New Orders',
          type: 'item',
          url: '/dashboard/new-orders',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        {
          id: 'Filled Orders',
          title: 'Filled Orders',
          type: 'item',
          url: '/dashboard/filled-orders',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        {
          id: 'Dispatched Orders',
          title: 'Dispatched Orders',
          type: 'item',
          url: '/dashboard/dispatched-orders',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        {
          id: 'Delivered Orders',
          title: 'Delivered Orders',
          type: 'item',
          url: '/dashboard/delivered-orders',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        {
          id: 'ordertracking',
          title: 'Order Tracking',
          type: 'item',
          url: '/dashboard/order-tracking',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        
        // {
        //   id: 'Medicines',
        //   title: 'Medicines',
        //   type: 'item',
        //   url: '/dashboard/medicines',
        //   icon: 32, //ICON Name
        //   breadcrumbs: false
        // },
        // {
        //   id: 'ComfortKits',
        //   title: 'Comfort Kits',
        //   type: 'item',
        //   url: '/dashboard/comfortKits',
        //   icon: 32, //ICON Name
        //   breadcrumbs: false
        // },
        // {
        //   id: 'Users',
        //   title: 'Users',
        //   type: 'item',
        //   url: '/dashboard/users',
        //   icon: 32, //ICON Name
        //   breadcrumbs: false
        // },
      ]
    };
  }
  else if( role_id == 7){
    dashboard ={
      id: 'group-dashboard',
      title: 'Drivers',
      type: 'group',
      children: [
        {
          id: 'Drivers',
          title: 'New Orders',
          type: 'item',
          url: '/dashboard/drivers',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
      ]
    }
  }
  else if( role_id == 4){
    dashboard ={
      id: 'group-dashboard',
      title: 'Orders',
      type: 'group',
      children: [
        {
          id: 'orders',
          title: 'Your Orders',
          type: 'item',
          url: '/dashboard/your-orders',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        {
          id: 'ordertracking',
          title: 'Order Tracking',
          type: 'item',
          url: '/dashboard/order-tracking',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        
      ]
    }
  }
  else if( role_id == 3){
    dashboard ={
      id: 'group-dashboard',
      title: 'Orders',
      type: 'group',
      children: [
        {
          id: 'Order',
          title: 'Orders',
          type: 'item',
          url: '/dashboard/orders',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        {
          id: 'ordertracking',
          title: 'Order Tracking',
          type: 'item',
          url: '/dashboard/order-tracking',
          icon: 32, //ICON Name
          breadcrumbs: false
        },
        
      ]
    }
  }
  return {items: [dashboard]}
};

export default getMenuItems;
