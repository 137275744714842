import { lazy } from 'react';

// project import
import Loadable from '../components/Loadable';
import MinimalLayout from '../layout/minimalLayout';

// render - login
const AuthLogin = Loadable(lazy(() => import('../pages/authentication/Login')));
const AuthRegister = Loadable(lazy(() => import('../pages/authentication/Register')));

// ==============================|| AUTH ROUTING ||============================== //

const LoginRoutes = {
  path: '/',
  element: <MinimalLayout />,
  children: [
    {
      index: true,
      element: <AuthLogin role_id={2} />
    },
    {
      path: 'nurse/login',
      element: <AuthLogin role_id={4} />
    },
    {
      path: 'pharmacy/login',
      element: <AuthLogin role_id={2} />,
    },
    {
      path: 'driver/login',
      element: <AuthLogin role_id={7} />,
    },
    {
      path: 'hospice/login',
      element: <AuthLogin role_id={3} />,
    },
    {
      path: 'register',
      element: <AuthRegister />
    }
  ]
};

export default LoginRoutes;
