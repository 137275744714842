// reducers.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  drivers: [],
  loadingOnDeliver: false,
  successOnDeliver: false,
  errorOnDeliver: false,
  error: null,
  viewDriver: null,
  pageCount: null,
  currentPage: null,
  loadingOnCreate: false,
  successOnCreate: false,
  errorOnCreate:false,
  success: false,
};

const driverSlice = createSlice({
  name: 'drivers',
  initialState,
  reducers: {
    resetCreateOrderState: (state)=>{
      state.loadingOnCreate = false
      state.successOnCreate = false
      state.errorOnCreate = false
    },
    viewNewOrderRequest: (state, action) => {
      state.loading = true;
      state.viewNewOrder = null;
    },
    viewNewOrderSuccess: (state, action) => {
      state.loading = false;
      const response = action.payload.data;
      state.viewNewOrder = response
    },
    viewNewOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchDriversRequest: (state, action) => {
      state.loading = true;
      state.drivers = null;
    },
    fetchDriversSuccess: (state, action) => {
      state.loading = false;
      state.drivers = action.payload.data;
      state.pageCount = action.payload.pagination.last_page;
      state.currentPage = action.payload.pagination.current_page;
      console.log(action.payload.data);
    },
    fetchDriversFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    searchDriversRequest: (state) => {
      state.loading = true;
    },
    searchDriversSuccess: (state, action) => {
      state.loading = false;
      state.drivers = action.payload.data;
    },
    searchDriversFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    deliverOrderRequest: (state, action) => {
      state.loadingOnDeliver = true;
      state.successOnDeliver = false;
      state.errorOnDeliver = false;
    },
    deliverOrderSuccess: (state, action) => {
      state.loadingOnDeliver = false;
      state.successOnDeliver = true;
      
      state.loading = false;
    },
    deliverOrderFailure: (state, action) => {
      state.loadingOnDeliver = false;
      state.errorOnDeliver = true;
      
      state.loading = false;
    },
    newPageRequest: (state) => {
      state.loading = true;
    },
    newPageSuccess: (state, action) => {
      state.loading = false;
      state.drivers = action.payload;
      state.pageCount = action.payload.pagination.last_page;
      state.currentPage = action.payload.pagination.current_page;
      
    },
    newPageFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    
  },
});

export const {
  viewNewOrderFailure,
  viewNewOrderRequest,
  viewNewOrderSuccess,
  fetchDriversRequest,
  fetchDriversSuccess,
  fetchDriversFailure,
  searchDriversRequest,
  searchDriversSuccess,
  searchDriversFailure,
  newPageRequest,
  newPageSuccess,
  newPageFailure,
  resetCreateOrderState,
  deliverOrderRequest,
  deliverOrderSuccess,
  deliverOrderFailure,
} = driverSlice.actions;

export default driverSlice.reducer;
