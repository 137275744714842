// reducers.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  newOrders: [],
  error: null,
  viewNewOrder: null,
  pageCount: null,
  orderTrackingPageCount: null,
  currentPage: null,
  currentTrackingPage: null,
  hospice: null,
  pharmacy: null,
  patient: [],
  nurse: [],
  comfortKit: null,
  driver: null,
  loadingOnCreate: false,
  success: false,
  successOnCreate: false,
  errorOnCreate:false,
  inheritedData: null,
  orderTracking: null,
};

const newOrderSlice = createSlice({
  name: 'newOrders',
  initialState,
  reducers: {
    resetOrderTrackingState: (state) => {

    },
    clearInheritedData: (state)=> {
      state.inheritedData = null;
    },
    setInheritedData: (state, action) => {
      state.inheritedData = action.payload
    },
    resetCreateOrderState: (state)=>{
      state.loadingOnCreate = false
      state.successOnCreate = false
      state.errorOnCreate = false
      state.hospice= null
      state.pharmacy= null
      state.patient= []
      state.nurse= []
      state.comfortKit= null
    },
    setSuccess: (state, action)=> {
      state.success= false;
    },
    setLoading: (state, action)=> {
      state.loading= false;
    },
    setNewOrders: (state, action) => {
      state.newOrders = action.payload;
    },
    addNewOrder: (state, action) => {
      state.newOrders.push(action.payload);
    },
    editNewOrder: (state, action) => {
      const index = state.newOrders.findIndex((newOrder) => newOrder.id === action.payload.id);
      if (index !== -1) {
        state.newOrders[index] = action.payload;
      }
    },
    deleteNewOrder: (state, action) => {
      state.newOrders = state.newOrders.filter((newOrder) => newOrder.id !== action.payload);
    },
    setSelectedNewOrder: (state, action) => {
      state.selectedNewOrder = action.payload;
    },
    toggleEditForm: (state) => {
      state.isEditFormOpen = !state.isEditFormOpen;
    },
    setFilteredRows: (state, action) => {
      state.filteredRows = action.payload;
    },
    createNewOrderRequest: (state, action) => {
      state.loadingOnCreate = true;
      state.successOnCreate = false;
      state.errorOnCreate = false;
    },
    createNewOrderSuccess: (state, action) => {
      state.successOnCreate = true;
      state.loadingOnCreate = false;
    },
    createNewOrderFailure: (state, action) => {
      state.loadingOnCreate = false;
      state.errorOnCreate = true;
    },
    viewNewOrderRequest: (state, action) => {
      state.loading = true;
      state.viewNewOrder = null;
    },
    viewNewOrderSuccess: (state, action) => {
      state.loading = false;
      const response = action.payload.data;
      console.log(response.status)
      state.viewNewOrder = response
    },
    viewNewOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    deleteNewOrderRequest: (state, action) => {
      state.loading = true;
      state.newOrders = state.newOrders.filter((newOrder) => newOrder.id !== action.payload.id);

    },
    deleteNewOrderSuccess: (state, action) => {
      state.loading = false;
      console.log(action.payload)
    },
    deleteNewOrderFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchNewOrdersRequest: (state, action) => {
      state.loading = true;
      state.newOrders = null;
    },
    fetchNewOrdersSuccess: (state, action) => {
      state.loading = false;
      state.newOrders = action.payload.data;
      state.pageCount = action.payload.pagination.last_page;
      state.currentPage = action.payload.pagination.current_page;
      console.log(action.payload.data);
    },
    fetchNewOrdersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    fetchOrdersRequest: (state, action) => {
      state.loading = true;
      state.newOrders = null;
    },
    fetchOrdersSuccess: (state, action) => {
      state.loading = false;
      state.newOrders = action.payload.data;
      state.pageCount = action.payload.pagination.last_page;
      state.currentPage = action.payload.pagination.current_page;
      console.log(action.payload.data);
    },
    fetchOrdersFailure: (state, action) => {
      state.loading = false;
      state.error = action.payload;
    },
    searchNewOrdersRequest: (state) => {
      state.loading = true;
    },
    searchNewOrdersSuccess: (state, action) => {
      state.loading = false;
      state.newOrders = action.payload.data;
    },
    searchNewOrdersFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    searchOrdersRequest: (state) => {
      state.loading = true;
    },
    searchOrdersSuccess: (state, action) => {
      state.loading = false;
      state.newOrders = action.payload.data;
    },
    searchOrdersFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    newPageRequest: (state) => {
      state.loading = true;
    },
    newPageSuccess: (state, action) => {
      state.loading = false;
      state.newOrders = action.payload;
    },
    newPageFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    newOrderTrackingPageRequest: (state) => {
      state.loading = true;
    },
    newOrderTrackingPageSuccess: (state, action) => {
      state.loading = false;
      state.orderTracking = action.payload;
    },
    newOrderTrackingPageFailure: (state, action) => {
      state.loading = false;
      state.error = action.error;
    },
    hospiceRequest: (state,action) => {
      state.loading = true;
    },
    hospiceSuccess: (state, action) => {
      state.loading = false;
      state.hospice = action.payload
       // Initialize filteredUsers with all Users
    },
    hospiceFailure: (state, action) => {
      state.loading = false;
    },
    patientRequest: (state,action) => {
      state.loading = true;
    },
    patientSuccess: (state, action) => {
      state.loading = false;
      state.patient = action.payload
       // Initialize filteredUsers with all Users
    },
    comfortKitRequest: (state, action) => {
      state.loading = true;
      state.comfortKit = null;
    },
    comfortKitSuccess: (state, action) => {
      state.loading = false;
      state.comfortKit = action.payload.data;
    },
    comfortKitFailure: (state, action) => {
      state.loading = false;
    },
    patientFailure: (state, action) => {
      state.loading = false;
    },
    nurseRequest: (state,action) => {
      console.log('i am in new orders ', action.payload)
      state.loading = true;
    },
    nurseSuccess: (state, action) => {
      state.loading = false;
      state.nurse = action.payload
       // Initialize filteredUsers with all Users
    },
    nurseFailure: (state, action) => {
      state.loading = false;
    },
    pharmacyRequest: (state,action) => {
      state.loading = true;
      state.pharmacy = null;
    },
    pharmacySuccess: (state, action) => {
      state.loading = false;
      state.pharmacy = action.payload
       // Initialize filteredUsers with all Users
    },
    pharmacyFailure: (state, action) => {
      state.loading = false;
    },
    orderTrackingRequest: (state,action) => {
      state.loadingOnCreate = true;
      state.orderTracking = null;
    },
    orderTrackingSuccess: (state, action) => {
      state.loadingOnCreate = false;
      state.orderTracking = action.payload.data;
      state.orderTrackingPageCount = action.payload.pagination.last_page;
      state.currentTrackingPage = action.payload.pagination.current_page;
    },
    orderTrackingFailure: (state, action) => {
      state.loadingOnCreate = false;
    },
    driverRequest: (state,action) => {
      state.loading = true;
      state.driver = null;
    },
    driverSuccess: (state, action) => {
      state.loading = false;
      state.driver = action.payload
       // Initialize filteredUsers with all Users
    },
    driverFailure: (state, action) => {
      state.loading = false;
    },
    changeStatusRequest: (state, action) => {
      state.loading = true;
    },
    changeStatusSuccess: (state, action) => {
      state.loading = false;
      state.success = true;
    },
    changeStatusFailure: (state, action) => {
      state.loading = false;
    },
    
  },
});

export const {
  setNewOrders,
  addNewOrder,
  editNewOrder,
  deleteNewOrder,
  setSelectedNewOrder,
  toggleEditForm,
  setFilteredRows,
  deleteNewOrderRequest,
  deleteNewOrderFailure,
  deleteNewOrderSuccess,
  viewNewOrderFailure,
  viewNewOrderRequest,
  viewNewOrderSuccess,
  createNewOrderRequest,
  createNewOrderSuccess,
  createNewOrderFailure,
  fetchNewOrdersRequest,
  fetchNewOrdersSuccess,
  fetchNewOrdersFailure,
  fetchOrdersRequest,
  fetchOrdersSuccess,
  fetchOrdersFailure,
  searchNewOrdersRequest,
  searchNewOrdersSuccess,
  searchNewOrdersFailure,
  searchOrdersRequest,
  searchOrdersSuccess,
  searchOrdersFailure,
  newPageRequest,
  newPageSuccess,
  newPageFailure,
  hospiceRequest,
  hospiceSuccess,
  hospiceFailure,
  nurseRequest,
  nurseSuccess,
  nurseFailure,
  pharmacyRequest,
  pharmacySuccess,
  pharmacyFailure,
  patientRequest,
  patientSuccess,
  patientFailure,
  changeStatusRequest,
  changeStatusSuccess,
  changeStatusFailure,
  driverRequest,
  driverSuccess,
  driverFailure,
  comfortKitRequest,
  comfortKitSuccess,
  comfortKitFailure,
  resetCreateOrderState,
  setSuccess,
  setLoading,
  clearInheritedData,
  setInheritedData,
  resetOrderTrackingState,
  orderTrackingRequest,
  orderTrackingSuccess,
  orderTrackingFailure,
  newOrderTrackingPageRequest,
  newOrderTrackingPageSuccess,
  newOrderTrackingPageFailure,
} = newOrderSlice.actions;

export default newOrderSlice.reducer;
